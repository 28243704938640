.video-tile {
    border: none;
    border-radius: 0;
    margin: 20px;
    overflow: hidden;
    width: 1792px; /* Set a fixed width for all video tiles */
    position: relative;
    background-color: #020202; /* Dark background */
    color: white; /* Light text for contrast */
    height: 1650px;
  }
  
  .title-bar {
    height: 30px;
    background-color: #227139; /* Green color */
    padding: 10px;
    color: rgb(6, 6, 6);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .regenerate-button {
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    cursor: pointer;
    float:right;
  }
  
  .regenerate-button:hover {
    color: #d4d4d4;
  }
  
  .edit-button {
    background: none;
    border: none;
    color: rgb(7, 7, 7);
    font-size: 1.2em;
    cursor: pointer;
    float:right;
  }
  
  .edit-button:hover {
    color: #d4d4d4;
  }
  
  .scene-video {
    width: 1792px; /* Set the width of the video */
    height: 1024px; /* Set the height of the video */
    object-fit: fill; /* Ensure the video covers the set dimensions */
    filter: brightness(50%);
  }

  .scene-overview {
    padding: 10px;
    background-color: #020202; /* Darker background for the overview */
  }
  
  .additional-info summary {
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Flex container for dialogue text and audio */
  .dialogue-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  .audio-container {
    margin-left: 1px;
    display: inline-flex;
    align-items: center;
    background-color: #020202; /* Dark background */
    padding: 0px 0px;
    border: none;
    height: 1.5em; /* Match the height of the font */
    width: 100%;
  }
  
  .audio-container audio {
    height: 100%;
    width: 250px;
    border: none; /* Set border color directly */
    box-shadow: none; /* Remove box-shadow */
    outline: none; /* Remove outline */
  }
  
  /* Custom styles for the audio controls */
  .audio-container audio::-webkit-media-controls-panel {
    background-color: #000000; /* Set the background color to black */
    border: none;
    box-shadow: none; /* Remove box-shadow */
    outline: none; /* Remove outline */
  }
  
  .audio-container audio::-webkit-media-controls-current-time-display,
  .audio-container audio::-webkit-media-controls-time-remaining-display,
  .audio-container audio::-webkit-media-controls-fullscreen-button  {
    display: none;
  }
  
  /* Reduce space between play button and seek bar */
  .audio-container audio::-webkit-media-controls-play-button,
  .audio-container audio::-webkit-media-controls-volume-slider,
  .audio-container audio::-webkit-media-controls-mute-button {
    margin: 1px; /* Adjust this value to reduce space */
    padding: 1px;
    filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
  }
  
  .audio-container audio::-webkit-media-controls-timeline {
    margin-left: 1px; /* Adjust this value to reduce space */
    padding: 1px;
    width: 45px;
    height: 1px;
    filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
  }
  
  .audio::-webkit-media-controls-toggle-closed-captions-button {
    filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
  }
  
  .audio-list {
    padding-inline-start: 0;
    height: 500 px;
    width: 100%;
    overflow: auto;
  }
  
  /* Ensure the video tile takes full width on smaller screens */
  @media (max-width: 1792px) {
    .video-tile {
      width: 100%;
    }
  
    .scene-video {
      width: 100%; /* Ensure the video takes full width on smaller screens */
      height: auto; /* Maintain aspect ratio */
    }
  }

  .video-additional-info {
    padding: 10px;
    background-color: #020202; /* Darker background for the additional info */
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .media-notes-container {
    padding: 10px;
    background-color: #020202; /* Darker background for the additional info */
    margin: 5px 0;
    width: 40%;
  }

  .waveform-play-pause-button {
    background: none;
    border: none;
    font-size: 20px;
    margin-right: 5px;
    margin-top: 0px;
    cursor: pointer;
    color: #4CAF50;
    padding: 0;
    display: inline-block;
    position: relative;
    bottom: 15px;
  }
  
  .waveform-download-button {
    background: none;
    border: none;
    font-size: 30px;
    margin-right: 10px;
    cursor: pointer;
    color: #4CAF50;
    margin: 0;
    padding: 0;
    position: relative;
    bottom: 15px;
    font-weight: bold;
  }

  .waveform-button-container {
    margin-top: 0.5px;
    margin-left: 3px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .waveform {
    display: inline-block;
    width: 70%;
    height: 70px;
    margin-top: 10px;
    margin-bottom: 1px;
    margin: 1px;
    padding: 1px;
  }

  .waveform-button-container {
    margin-top: 0.5px;
    margin-left: 3px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 80%;
  }

  .notes-textarea {
    width: 100%;
    height: 300px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: none;
    background-color: #111010;
    font-size: 16px;
    resize: none;
    margin-top: 30px;
    margin-right: 20px;
    color: rgb(255, 255, 255);
  }