.project-sidebar {
  width: 600px;
  height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 20px 20px 0; /* Remove padding from the bottom */
  font-size: 40px;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.create-project-button {
  color: #0f0;
  background-color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 80px;
  margin-top: 35px;
  margin-left: auto;
  font-weight: bold;
}

.project-list {
  list-style: none;
  padding: 0;
}

.project-list li {
  padding: 10px;
  cursor: pointer;
  font-size: 30px;
}

.project-list li:hover {
  background-color: #333;
}

.project-list .selected .project-title {
  color: #0f0; /* Green color for selected project */
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.project-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
}

.project-header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow {
  cursor: pointer;
  margin-right: 5px;
}

.project-title {
  cursor: pointer;
}

.project-children {
  list-style: none;
  padding-left: 20px;
}

.project-child, .scene-item, .media-item, .audio-item {
  cursor: pointer;
  padding-left: 20px; /* Indent child elements */
  margin-top: 5px;
}

.scene-list, .media-list, .audio-list {
  list-style-type: none;
  padding-left: 20px; /* Indent lists for better visual hierarchy */
}

.project-child:hover, .scene-item:hover, .media-item:hover, .audio-item:hover {
  background-color: #444;
}

.logout-button {
  background-color: #680404; /* Make it red to stand out */
  color: #ffffff;
  border: none;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  font-size: 40px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 80px;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove any padding */
  box-sizing: border-box; /* Prevent padding or border from affecting size */
}

.logout-button:hover {
  background-color: #d93636;
}

.context-menu {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 8px;
  border-radius: 4px;
  z-index: 1000;
}

.context-menu button {
  background: none;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.context-menu button:hover {
  background-color: #555;
}