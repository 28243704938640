.screenplay-tile {
  background-color: #020202;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 3000px;
  font-size: 25px;
}

.screenplay-tile pre {
  white-space: pre-wrap;  /* Allows text to wrap */
  word-wrap: break-word;  /* Break long words */
  overflow: hidden;  /* Hide any text overflow */
  max-width: 100%;  /* Ensure the text doesn't exceed the container width */
  font-size: 20px;
}

.loading-animation {
display: block;
margin: 0 auto;
width: 100px; /* Adjust size as needed */
height: 100px; /* Adjust size as needed */
}

.screenplay-overview {
  cursor: pointer;
}

.full-screenplay {
  margin-top: 20px;
}

.show-more-button {
  padding: 10px 20px;
  font-size: 20px;
  background-color: #28a745;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 20px;
  width: 150px;
}

.generate-scenes-button {
  padding: 10px 20px;
  font-size: 20px;
  background-color: #28a745;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 20px;
  width: 250px;
}

.generate-scenes-button:disabled {
  background-color: #ccc;
}

.buttons-container {
  display: flex;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.min-max-button {
  color: #0f0;
  background-color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
}

.save-edit-button {
  background-color: rgb(36, 128, 36);
}