.character-slideshow-container {
  display: flex;
  align-items: flex-start; /* Align the content to the top-left */
  background-color: #121212;
  padding: 20px;
  padding-top: 0;
  border-radius: 10px;
  position: relative;
  margin: 0; /* Remove auto margin for left alignment */
}

.character-slideshow {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start; /* Align the slideshow to the left */
  gap: 35px; 
}

.character-tile {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1px;
  margin-top: -22px;
  color: rgb(36, 132, 52);
  font-weight: bold;
  font-size: 40px;
  user-select: none;
  height: 100px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.prev:disabled,
.next:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
