.login-container {
    position: absolute;
    left: 20px; /* Adjust the value as needed for horizontal positioning */
    width: 500px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    color: white;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Space between the buttons */
  }

  .login-button, .register-button {
    width: 100%;
    padding: 10px;
    background-color: rgb(18, 158, 18);;
    color: 000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #01501a;
  }
  
  .register-button:hover {
    background-color: #01501a;
  }