.scene-tile {
  border: none;
  border-radius: 0;
  margin: 20px;
  overflow: hidden;
  width: 1792px; /* Set a fixed width for all scene tiles */
  position: relative;
  background-color: #020202; /* Dark background */
  color: white; /* Light text for contrast */
}

.title-bar {
  height: 30px;
  background-color: #227139; /* Green color */
  padding: 10px;
  color: rgb(6, 6, 6);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regenerate-button {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 20px;
  cursor: pointer;
  float:right;
  margin-bottom: 10px;
  font-weight: bold;
}

.regenerate-button:hover {
  color: #d4d4d4;
}

.edit-button {
  background: none;
  border: none;
  color: rgb(7, 7, 7);
  font-size: 1.2em;
  cursor: pointer;
  float:right;
}

.edit-button:hover {
  color: #d4d4d4;
}

.scene-image {
  width: 1792px; /* Set the width of the image */
  height: 1024px; /* Set the height of the image */
  object-fit: fill; /* Ensure the image covers the set dimensions */
}

.scene-overview {
  padding: 10px;
  background-color: #020202; /* Darker background for the overview */
}

.additional-info {
  padding: 10px;
  background-color: #020202; /* Darker background for the additional info */
}

.additional-info summary {
  cursor: pointer;
  font-weight: bold;
}

.additional-info p {
  margin: 5px 0;
}

/* Flex container for dialogue text and audio */
.dialogue-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0;
  padding: 0;
}

.audio-container {
  margin-left: 1px;
  display: inline-flex;
  align-items: center;
  background-color: #020202; /* Dark background */
  padding: 0px 0px;
  border: none;
  height: 1.5em; /* Match the height of the font */
  width: 100%;
}

.audio-container audio {
  height: 100%;
  width: 100%;
  border: none; /* Set border color directly */
  box-shadow: none; /* Remove box-shadow */
  outline: none; /* Remove outline */
}

/* Custom styles for the audio controls */
.audio-container audio::-webkit-media-controls-panel {
  background-color: #000000; /* Set the background color to black */
  border: none;
  box-shadow: none; /* Remove box-shadow */
  outline: none; /* Remove outline */
}

.audio-container audio::-webkit-media-controls-volume-slider,
.audio-container audio::-webkit-media-controls-mute-button,
.audio-container audio::-webkit-media-controls-current-time-display,
.audio-container audio::-webkit-media-controls-time-remaining-display,
.audio-container audio::-webkit-media-controls-fullscreen-button  {
  display: none;
}

/* Reduce space between play button and seek bar */
.audio-container audio::-webkit-media-controls-play-button {
  margin: 1px; /* Adjust this value to reduce space */
  padding: 1px;
  filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
}

.audio-container audio::-webkit-media-controls-timeline {
  margin-left: 1px; /* Adjust this value to reduce space */
  padding: 1px;
  width: 45px;
  height: 1px;
  filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
}

.audio::-webkit-media-controls-toggle-closed-captions-button {
  filter: invert(54%) sepia(76%) saturate(2157%) hue-rotate(70deg) brightness(94%) contrast(96%); /* Change color to #227139 */
}

.scenes-audio-list {
  padding-inline-start: 0;
  width: 100%;
}

.video-generate-button {
  background-color: #28a745;
  border: none;
  color: #000000;
  font-size: 1.2em;
  cursor: pointer;
  float:right;
  margin: 20px;
  padding:10px;
}

.video-generate-button:hover {
  color: #ffffff;
}

/* Ensure the scene tile takes full width on smaller screens */
@media (max-width: 1792px) {
  .scene-tile {
    width: 100%;
  }

  .scene-image {
    width: 100%; /* Ensure the image takes full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
}
