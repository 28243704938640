.main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  background-color: #121212;
  height: 100;
  overflow: hidden;
}

.story-input {
  max-width: 500px;
  margin-right: 20px;
  padding: 20px;
  background-color: #000000;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  margin-bottom: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 90%;
}

textarea {
  margin-bottom: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 90%;
  height: 100px;
}

.generate-screenplay-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 20px;
  width: 250px;
}

.generate-screenplay-button:disabled {
  background-color: #ccc;
}

.scenes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.horizontal-scenes-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.screenplay-tile {
  background-color: #000000;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 3600px;
  text-wrap: wrap;
}

.screenplay-overview {
  cursor: pointer;
}

.full-screenplay {
  margin-top: 20px;
}

.vertical-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.toolbar-button {
  padding: 10px 20px;
  font-size: 25px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1px;
  width: 100px;
  height: 90px;
}

.toolbar-button:hover {
  background-color: #8f8f8f;
}

.content-container {
  flex: 1;
  background-color: #121212;
  color: #fff;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling within the content container */
  height: 100vh;
  gap: 0;
  display: flex;
  flex-direction: column;
}

.horizontal-screenplay-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.vertical-buttons-screenplay-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9998; /* Place it below the enlarged CharacterTile */
  display: none; /* Initially hidden */
}

.overlay.show {
  display: block;
}

.character-bible-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 3550px;
  row-gap: 0;
  gap: 0;
}

.character-bible-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100px;
  padding-left: 20px;
}

.horizontal-character-bible-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  padding-top: 0;
  background-color: #000000;
}

.min-max-character-bible-button {
  color: #0f0;
  background-color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
