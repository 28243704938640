.new-project-dialog {
    background-color: #111;
    color: #fff;
    padding: 20px;
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 1400px;
    height: 450px;
    font-size: 25px;
  }
  
  .new-project-dialog h2 {
    margin-top: 0;
  }
  
  .new-project-dialog label {
    display: block;
    margin-top: 10px;
  }
  
  .new-project-dialog input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 10px;
    font-size: 25;
    height: 30px;
  }
  
  .new-project-dialog textarea {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 10px;
    font-size: 30;
    height: 500px;
  }

  .new-project-dialog button {
    background-color: #227139;
    color: #000;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    font-weight:bold ;
  }
  