html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 10000px;
  background-size: cover;
  background-size: 100% 100%;
  overflow:auto; 
  background-color: #121212;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling on the root element */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
