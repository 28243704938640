.character-tile {
  display: flex;
  flex-direction: row;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 830px;
  margin: 20px auto;
  transition: transform 0.5s ease-in-out;
}

.character-tile.enlarged {
  width: 30vw;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2); /* Scale the entire tile 2x */
  z-index: 9999;
  overflow-y: auto;
}

.character-header {
  display: flex;
  align-items: flex-start;
}

.character-image {
  max-width: 300px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 10px;
  margin-top: 50px;
  transition: transform 0.5s ease-in-out;
}

.character-tile.enlarged .character-image {
  transform: scale(.8); /* Scale the image 2x */
}

.character-info {
  display: flex;
  flex-direction: column;
}

.character-info h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  transition: font-size 0.5s ease-in-out;
}

.character-tile.enlarged .character-info h1 {
  font-size: 50px; /* Double the font size of the character name */
}

.character-details {
  flex: 1;
  line-height: 1.8;
  white-space: pre-wrap;
  transition: transform 0.5s ease-in-out;
}

.character-tile.enlarged .character-details {
  transform: scale(1); /* Scale the description 2x */
}

.character-heading {
  font-size: 24px; 
  margin-top: 5px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  line-height: .5;
}

.character-heading.enlarged .character-details {
  transform: scale(2); 
}

.character-content {
  margin-bottom: 15px;
  font-weight: 200;
}

.scrollable {
  max-height: 500px;
  overflow-y: auto;
}
